import { CircularProgress, Dialog, DialogTitle } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow/TableRow";
import React, { Fragment, h } from "react";
import { connect } from "react-redux";

function round2(n) {
  return Math.round(n * 100) / 100;
}
function round1(n) {
  return Math.round(n * 10) / 10;
}

function StatRowRaw({ row, cols, toggleFilter, data, year }) {
  cols = cols.map((o) => o.get("label")).toJS();
  data = data && data.toJS();
  let { count, path, expanded, id } = row;
  return (
    <React.Fragment>
      <TableRow
        style={{ cursor: "pointer" }}
        key={id}
        onClick={() => toggleFilter(path)}
      >
        <TableCell rowSpan={2}>
          {path
            .slice(1)
            .map((o) => "\xa0\xa0\xa0")
            .join("")}
          {path.length > 4 ? "\xa0\xa0\xa0" : expanded ? "⊟" : "⊞"} {}
          {path[path.length - 1]} {}
        </TableCell>
        {cols.map((col) => (
          <TableCell>
            {(() => {
              if (!data) return <CircularProgress />;
              if (col === "Antal Materialer") return data.count;
              if (col === "Antal Udlån") return data.loans[year];
              if (col === "Cirkulationstal")
                return round2(data.loans[year] / data.count);
              if (col === "Tilvækst") return data.tilvaekst[year];
              if (col === "Afgang") return data.afgang[year];
              if (col === "Procentvis tilvækst/afgang i materialer")
                return (
                  round1(
                    (((data.tilvaekst[year] - data.afgang[year]) | 0) /
                      data.count) *
                      100,
                  ) + "%"
                );
              return `Unhandled column:${col}`;
            })()}
          </TableCell>
        ))}
      </TableRow>
      <TableRow key={id + "2"}></TableRow>
    </React.Fragment>
  );
}
export const StatRow = connect(
  (state, props) => ({
    data: state.getIn(["aggregations", props.row.id]),
    year: state.getIn(["ui", "year"]),
  }),
  (dispatch) => ({
    toggleFilter: (path) => dispatch({ type: "TOGGLE_FILTER", path }),
  }),
)(StatRowRaw);
