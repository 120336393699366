import { dispatch } from "./store";
import { foreachMaterial } from "./stat";

export function activeFilters(filters) {
  function findOpstilling(o, acc, path) {
    if (!o) return acc;
    let cond = [];

    let prefix = [
      null,
      "filial:",
      "afdeling:",
      "opstilling:",
      "delopstilling:",
    ];

    for (let i = 1; i < path.length; i++) {
      cond.push(prefix[i] + path[i]);
    }
    acc.push({
      path,
      cond,
      id: path.join("|"),
      count: o.get("_count"),
      expanded: o.get("_expanded"),
    });
    if (o.get("_expanded")) {
      o.map((v, k) => {
        if (!k.startsWith("_")) {
          findOpstilling(v, acc, [...path, k]);
        }
      });
    }
    return acc;
  }
  let result = findOpstilling(filters.get("Opstilling"), [], ["Opstilling"]);
  result.sort((a, b) => (a.id > b.id ? 1 : -1));
  return result;
}

export async function findFilters() {
  let i = 0,
    j = 0;
  console.time("find_filters");
  let DK5s = {},
    tags = {},
    locs = {};
  function inc(o, k) {
    o[k] = (o[k] || 0) + 1;
  }
  await foreachMaterial((material) => {
    let DK5 = material.meta.find((s) => s.startsWith("tag:DK5/"));
    DK5 =
      DK5 ||
      material.meta.find((s) => s === "tag:Skønlitteratur") ||
      "tag:Andet";
    let filial = "(ingen filial)",
      afdeling = "(ingen afdeling)",
      opstilling = "(ingen opstilling)",
      delopstilling = "(ingen delopstilling)";
    for (const meta of material.meta) {
      if (meta.startsWith("filial")) {
        filial = meta.slice(7);
      }
      if (meta.startsWith("afdeling")) {
        afdeling = meta.slice(9);
      }
      if (meta.startsWith("opstilling")) {
        opstilling = meta.slice(11);
      }
      if (meta.startsWith("delopstilling")) {
        delopstilling = meta.slice(14);
      }
      if (meta !== DK5) {
        inc(tags, meta);
      }
    }
    let o, loc;
    if (!(material = locs[filial])) material = locs[filial] = {};
    loc = material;
    if (!(material = loc[afdeling])) material = loc[afdeling] = {};
    loc = material;
    if (!(material = loc[opstilling])) material = loc[opstilling] = {};
    loc = material;
    if (!(material = loc[delopstilling])) {
      material = loc[delopstilling] = { _count: 0 };
    }
    loc = material;
    ++loc._count;

    DK5 = DK5.slice(4);
    inc(DK5s, DK5);
  });
  let DK5tree = { id: "" };
  for (const DK5 in DK5s) {
    // TODO build DK5-tree-structure
  } //let filterIds = {opstilling: loc}
  function updateCount(o) {
    let sum = 0;
    for (const k in o) {
      if (k != "_count") {
        sum += updateCount(o[k]);
      } else {
        sum += o._count;
      }
    }
    o._count = sum;
    return sum;
  }
  updateCount(locs);
  let data = { locs };
  dispatch({
    type: "FILTER_IDS",
    data: { Opstilling: { ...locs, _expanded: true } },
  });
  console.timeEnd("find_filters");
}
