import React, { Fragment, h } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
} from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

export function Info({ showInfo, toggleInfo }) {
  return (
    <Dialog open={showInfo} onClose={toggleInfo} scroll="body" maxWidth="md">
      <DialogTitle>
        <SvgIcon fontSize="small">
          <InfoOutlined />
        </SvgIcon>
        {} BibStats
      </DialogTitle>
      <DialogContent>
        <p>
          BibStats er et tidligt preview/proof-of-concept på statistikværktøj
          til materialepleje og&nbsp;lignende. {}
          <em>
            Udviklingen er på et meget tidligt stadie, og slet ikke
            færdigtudviklet.
          </em>
        </p>

        <p>
          Hvis du kunne tænke dig, at det bliver udviklet færdigt, eller hvis du
          har ønsker/idéer til statistik, eller hvis du har lyst til at se
          statistikken for dit bibliotek, – så{" "}
          <em>send en email til Rasmus Erik, bibstats@solsort.dk</em>
          &nbsp; BibStats bliver kun videreudviklet hvis der er tilstrækkelig
          opbakning og interesse for projektet. Så{" "}
          <strong>
            send en mail hvis du ønsker at BibStats bliver udviklet.
          </strong>
        </p>

        <p>
          Grænseflade og statistik er baseret på input og wireframes fra
          Ballerup Bibliotek.
        </p>

        <p>
          Plan for videreudvikling, –{" "}
          <em>hvis der viser sig at være opbakning til projektet:</em>
        </p>
        <ul>
          <li>
            Gruppering på DK5 (forøjeblikket er det kun efter
            opstilling/delopstilling)
          </li>
          <li>
            Angivelse af år for statistik, og sammenligning over flere år
            (forøjeblikket vises kun for 2019)
          </li>
          <li>Visning af hvilke materialer der er indenfor hver gruppering</li>
          <li>Mulighed for eksklusion af fjernlån i statistikken</li>
          <li>Beholdningsalder for grupperingerne</li>
          <li>Wildcard for filial/opstilling</li>
          <li>Statistik for låner-køn/alder indenfor de enkelte gruppering</li>
          <li>
            "Antal Materialer" skal tage højde for tidsrum i stedet for blot at
            være det samlede antal materialer
          </li>
          <li>Løbende opdatering af statistikken</li>
          <li>
            <em>Bedre performance</em>
          </li>
          <li>Statistik for andre biblioteker</li>
          <li>[ dine idéer her 😉 ]</li>
          <li>
            Eventuelt:
            <ul>
              <li>Søgning efter gruppering/nøgleord</li>
              <li>Statistik på mere finkornet basis end årligt</li>
              <li>
                Statistik for anbefalinger og materialevisninger på hjemmesiden
                – <a href="https://bibspire.dk">BibSpire.dk</a>
              </li>
              <li>
                Statistik for generiske forsider når dette bliver implementeret
                – <a href="https://forsider.dk">forsider.dk</a>
              </li>
              <li>...</li>
            </ul>
          </li>
        </ul>

        <p>
          Formålet med BibStats er, at være et supplement og dække de
          informationsbehov, som ligger udover det som FAKTOR dækker. Hvis data
          allerede findes i en rapport i FAKTOR, så er der ingen grund til at
          inkludere det her. BibStats bygger foreløbigt på data fra Den Åbne
          Platform og Ballerup Biblioteks FAKTOR-database-adgang.
        </p>
        {/*
        <p>
          [Tilføjelse: Der lige annonceret nogle nye rapporter i FAKTOR, der
          muligvis overlapper med BibStats. Hvis der er overlap har der været
          dobbeltudvikling igen 🙁]
        </p>
          */}
        <p>
          Hvis jeg udvikler videre på BibStats, – så håber jeg / satser jeg på,
          at det kommer til at ligge og være forankret hos DBC, – da jeg med
          mine tidligere biblioteksprojekter har erfaret, at det nærmest er
          umuligt som selvstændig ildsjæl at lave innovation og løsninger for
          bibliotekerne (på grund af strukturer i de store organisationer i
          sektoren).
        </p>

        <p>
          <strong>
            Iøvrigt mener jeg at den anonymiserede udlånsstatistik etc. burde
            være åbne data på nationalt plan.{" "}
          </strong>
          Det er en ledelsesmæssig/bureaukratisk beslutning at de nationale data
          ikke er åbne. Data har tidligere været åbne og offentliggjort på{" "}
          <a href="https://hack4.dk">Hack4DK</a>. Der er intet teknisk der
          hindrer at de kan åbnes op. {}
          <em>
            Hvis data var åbne ville dette værktøj virke for samtlige
            biblioteker i stedet for kun Ballerup. Hvis data var åbne ville det
            give bedre <a href="https://bibspire.dk">anbefalinger</a> og derved
            flere udlån. Hvis data var åbne ville det gavne innovationen,
            hvilket kan ses af den måde de tidligere data på Hack4DK blev brugt.
          </em>{" "}
          {}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleInfo}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
