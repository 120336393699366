import { h } from "react";
import { render } from "react-dom";
import { dispatch, store } from "./store";
import "./reducers";
import { findFilters } from "./filters";
import { Root } from "./components/app";
import "./aggregator";
import { ownerDocument } from "@material-ui/core";

let elem =
  document.querySelector("#app") ||
  (() => {
    let elem = document.createElement("div");
    elem.id = "app";
    document.body.appendChild(elem);
    return elem;
  })();
render(Root(), elem);

findFilters();
