import infoUrl from "url:./stat.info";
import binUrl from "url:./stat.bin";
let bin: Uint8Array;
let info;

let loadingStat;

async function doLoadStat() {
  bin = new Uint8Array(await (await fetch(binUrl)).arrayBuffer());
  let o = await (await fetch(infoUrl)).json();
  info = {
    meta: o.meta.map((a) => a[0]),
    date: o.date.map((a) => a[0]),
    patron: o.patron.map((a) => JSON.parse(a[0])),
  };
}
export function loadStat() {
  if (!loadingStat) loadingStat = doLoadStat();
  return loadingStat;
}
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export async function foreachMaterial(f, limit = Infinity) {
  let pos = 0;

  function getByte() {
    return bin[pos++];
  }
  function getInt() {
    let b = getByte();
    if (b & 128) {
      let t = bin[pos];
      let result = ((b & 127) << 8) + getByte();
      return result;
    } else {
      return b - 1;
    }
  }
  function getList() {
    let result = [];
    let c = getInt();
    while (c !== -1) {
      result.push(c);
      c = getInt();
    }
    return result;
  }
  let getStr = () => String.fromCharCode.apply(String, getList());

  function getMaterial() {
    let o: any = {};
    o.faust = getStr();
    o.anskaffet = info.date[getInt()];
    o.bortskaffet = info.date[getInt()];
    o.meta = getList().map((i) => info.meta[i]);
    o.laan = [];
    let arr = getList();
    for (let i = 0; i < arr.length; i += 2) {
      o.laan.push({ date: info.date[arr[i]], patron: info.patron[arr[i + 1]] });
    }
    return o;
  }

  if (!info) await loadStat();
  let n = 0;
  while (pos < bin.length) {
    for (let i = 0; i < 10000 && pos < bin.length; ++i) {
      f(getMaterial());
      ++n;
      if (n >= limit) break;
    }
    if (n >= limit) break;
    await sleep(0);
  }
}
