import React, { Fragment, h } from "react";
import { connect } from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

function IndicatorTogglesRaw({ toggleIndicator, toggles }) {
  toggles = toggles.toJS();
  let i = 0;
  return (
    <Fragment>
      {toggles.map(({ label, enabled }) =>
        label ? (
          <FormControlLabel
            key={label}
            control={
              <Switch
                checked={!!enabled}
                onChange={() => toggleIndicator(label)}
              />
            }
            label={label}
          />
        ) : (
          <br key={++i} />
        ),
      )}
    </Fragment>
  );
}
export const IndicatorToggles = connect(
  (state) => ({
    toggles: state.getIn(["ui", "toggles"]),
  }),
  (dispatch) => ({
    toggleIndicator: (indicator) =>
      dispatch({ type: "TOGGLE_INDICATOR", indicator }),
  }),
)(IndicatorTogglesRaw);
