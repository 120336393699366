import { createStore } from "redux";
import { fromJS } from "immutable";

let reducers = {};

const reducer = (
  state = fromJS({
    ui: {
      year: "2019",
      toggles: [
        { label: "Antal Materialer", type: "column", enabled: true },
        { label: "Antal Udlån", type: "column", enabled: true },
        { label: "Cirkulationstal", type: "column", enabled: true },
        { label: "Tilvækst", type: "column" },
        { label: "Afgang", type: "column" },
        { label: "Procentvis tilvækst/afgang i materialer", type: "column", enabled: true },
        // { label: "Beholdningsalder" },
        /*
        { type: "newline" },
        { label: 2016, type: "year" },
        { label: 2017, type: "year" },
        { label: 2018, type: "year" },
        { label: 2019, type: "year" },
        { label: 2020, type: "year", enabled: true },
        { label: 2021, type: "year" },
        */
      ],
      showInfo: location.hash === "#info",
    },
    filters: { Opstilling: {} },
    aggregations: {},
  }),
  action,
) =>
  (
    reducers[action.type] ||
    ((o) => {
      console.log("unhandled dispatch", action.type);
      return o;
    })
  )(state, action);

export function setReducers(rs) {
  reducers = rs;
}
export const store = createStore(
  reducer,
  window["__REDUX_DEVTOOLS_EXTENSION__"] &&
    window["__REDUX_DEVTOOLS_EXTENSION__"](),
);
export const dispatch = (action) => store.dispatch(action);
