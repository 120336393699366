import React, { Fragment, h } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect, Provider } from "react-redux";
import { store } from "../store";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/styles";
import {
  Button,
  createMuiTheme,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { cyan, green } from "@material-ui/core/colors";
import { IndicatorToggles } from "./IndicatorToggles";
import { activeFilters } from "../filters";
import { StatRow } from "./StatRow";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Info } from "./Info";

useStyles = makeStyles((theme) => ({
  title: { flexGrow: 1 },
}));

const theme = createMuiTheme({
  palette: {
    primary: { main: green[800] },
    secondary: { main: cyan[800] },
  },
});

function AppRaw({
  filters,
  indicatorColumns,
  toggleInfo,
  showInfo,
  setYear,
  year,
}) {
  const classes = useStyles();
  let rows = activeFilters(filters);
  return (
    <Fragment>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            BibStats.dk &nbsp; {}
            <Select
              style={{ color: "#fff" }}
              variant="standard"
              value="ballerup"
            >
              <MenuItem value="ballerup">Ballerup Bibliotek</MenuItem>
            </Select>
          </Typography>
          <IconButton color="inherit" onClick={toggleInfo}>
            <InfoOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Info showInfo={showInfo} toggleInfo={toggleInfo} />
      <Container maxWidth="xl">
        <br />
        <IndicatorToggles />
        <Select
          variant="outlined"
          value={year}
          onChange={(o) => setYear(o.target.value)}
        >
          <MenuItem value="2017">2017</MenuItem>
          <MenuItem value="2018">2018</MenuItem>
          <MenuItem value="2019">2019</MenuItem>
          <MenuItem value="2020">
            2020 (Lavt udlån pga. Corona-lockdown)
          </MenuItem>
          <MenuItem value="2021">
            2021 (Lockdown og kun ca. fire måneder)
          </MenuItem>
        </Select>
        <br />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {indicatorColumns.toJS().map(({ label }) => (
                  <TableCell key={label}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StatRow row={row} cols={indicatorColumns} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <br />
        <Typography>
          Dette er en tidlig prototype / proof-of-concept. Hvis den vækker
          interesse, så kommer der nye udgaver med mere statistik og bedre
          performance.
        </Typography>
        <Typography>
          Hvis du vil have tilføjet dit bibliotek, er interesseret i fremtidig
          udgave, eller har ønsker/idéer til statistikken, så send en mail til
          Rasmus&nbsp;Erik, bibstats@solsort.dk
        </Typography>
        <Typography>
          Se også mere
          <Button size="small" color="inherit" onClick={toggleInfo}>
            info&nbsp;
            <InfoOutlined />
          </Button>{" "}
          &nbsp; <em>Fortæl gerne andre om bibstats.dk</em>
        </Typography>
      </Container>
    </Fragment>
  );
}

let App = connect(
  (state) => ({
    filters: state.get("filters"),
    showInfo: !!state.getIn(["ui", "showInfo"]),
    indicatorColumns: state
      .getIn(["ui", "toggles"])
      .filter((o) => o.get("enabled") && o.get("type") === "column"),
    year: state.getIn(["ui", "year"]),
  }),
  (dispatch) => ({
    toggleInfo: () => dispatch({ type: "TOGGLE_INFO" }),
    setYear: (year) => dispatch({ type: "SET_YEAR", year }),
  }),
)(AppRaw);

export function Root() {
  return (
    <Fragment>
      <CssBaseline />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </Fragment>
  );
}
