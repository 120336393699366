import { store } from "./store";
import { foreachMaterial } from "./stat";
import { activeFilters } from "./filters";
import { off } from "node:process";

let prevFilters;
let running = false,
  scheduled = false;

async function aggregate() {
  if (running) {
    scheduled = true;
    return;
  }
  running = true;
  scheduled = false;

  let state = store.getState();
  let filters = state.get("filters");
  if (filters.equals(prevFilters) /* add indicators later*/) {
    running = false;
    return;
  }
  prevFilters = filters;
  let rows = activeFilters(filters);
  let result = [];
  for (const row of rows) {
    let o = { count: 0, loans: {}, samples: [], tilvaekst: {}, afgang: {} };
    for (let i = 2013; i <= 2021; ++i) o.loans[i] = 0;
    result.push(o);
  }
  for (const row of rows) console.log(row.cond);
  console.time("aggregate");
  await foreachMaterial((m) => {
    let filial = "filial:(ingen filial)",
      afdeling = "afdeling:(ingen afdeling)",
      opstilling = "opstilling:(ingen opstilling)",
      delopstilling = "delopstilling:(ingen delopstilling)";
    for (const kw of m.meta) {
      if (kw.startsWith("filial:")) filial = kw;
      if (kw.startsWith("afdeling:")) afdeling = kw;
      if (kw.startsWith("opstilling:")) opstilling = kw;
      if (kw.startsWith("delopstilling:")) delopstilling = kw;
    }
    let meta = [filial, afdeling, opstilling, delopstilling];
    for (let i = 0; i < rows.length; ++i) {
      let ok = true;
      let row = rows[i];
      if (row.cond) {
        for (const cond of row.cond) {
          if (!meta.includes(cond)) {
            ok = false;
            break;
          }
        }
      }
      if (ok) {
        let r = result[i];
        ++r.count;
        for (const loan of m.laan) {
          ++r.loans[loan.date.slice(0, 4)];
        }
        if (m.anskaffet) {
          let y = m.anskaffet.slice(0, 4);
          r.tilvaekst[y] = (r.tilvaekst[y] || 0) + 1;
        }
        if (m.bortskaffet) {
          let y = m.bortskaffet.slice(0, 4);
          r.afgang[y] = (r.afgang[y] || 0) + 1;
        }
      }
    }
  });
  console.timeEnd("aggregate");
  for (let i = 0; i < rows.length; ++i) {
    store.dispatch({
      type: "AGGREGATOR_RESULT",
      rowId: rows[i].id,
      data: result[i],
    });
  }
  running = false;
  if (scheduled) setTimeout(aggregate, 0);
}

store.subscribe(aggregate);
