import { setReducers } from "./store";
import { fromJS } from "immutable";

setReducers({
  AGGREGATOR_RESULT: (state, { rowId, data }) =>
    state.setIn(["aggregations", rowId], fromJS(data)),
  FILTER_IDS: (state, action) => state.set("filters", fromJS(action.data)),
  INC_COUNT: (state, action) => state.update("count", (i) => i + 1),
  TOGGLE_INFO: (state, action) =>
    state.updateIn(["ui", "showInfo"], (showInfo) => {
      location.hash = showInfo ? "" : "#info";
      return !showInfo;
    }),
  TOGGLE_FILTER: (state, action) =>
    state.updateIn(["filters", ...action.path, "_expanded"], (o) => !o),
  TOGGLE_INDICATOR: (state, { indicator }) => {
    let pos = state
      .getIn(["ui", "toggles"])
      .findIndex((o) => o.get("label") === indicator);
    return state.updateIn(["ui", "toggles", pos, "enabled"], (o) => !o);
  },
  SET_YEAR: (state, action) => state.setIn(["ui", "year"], action.year),
});
